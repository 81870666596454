import React, { useEffect } from "react";
import UserManager from "../lib/userManager"
import SEO from "../components/SEO"
import Layout from "../components/Layout"

import { useRouter } from 'next/router'
import DomainHandler from "../lib/domainHandler";
import HomeLandingPage from "../components/landing-page/HomeLandingPage";
import Head from "next/head"

import { WPPagesAPI } from "../lib/ladder"
import WPParser from "../lib/parsePage"
import parse from 'html-react-parser'

export async function getServerSideProps(context) {
  if(context.req.headers.host.includes("iec")) {
    DomainHandler.redirectToIECJobs(context);
  }

  const {text} = await WPPagesAPI.home()

  const page = new WPParser(text)

  const pageBody = page.body
  const pageHead = page.head
  const pageTitle = page.title
  const bodyClass = page.bodyClass

  return {
    props: {
      data: "data",
      page: {
        title: pageTitle,
        head: pageHead,
        content: pageBody,
        bodyClass,
      },
    }
  }
}

export default function Home({page}) {
  const router = useRouter();

  useEffect(() => {
    if(window.location.href.includes("app.")) {
      if(!UserManager.signedIn()) {
        router.push("/login")
      } else if(UserManager.isAdmin()) {
        router.push('/admin')
      } else if(UserManager.isEmployer()) {
        router.push('/jobs');
      } else if(UserManager.isWorker()) {
        router.push('/profile');
      } else if(UserManager.isOrgManager()) {
        router.push('/organizations');
      }
    }
  })

  return (
    <Layout height={0.01} lander={true} WP classList={page.bodyClass}>
      <Head>
        {parse(page.head)}
      </Head>
      <SEO title={page.title} description={"Ladder is a construction labor hiring site built by contractors for the skilled trades."} path="/" override={true}/>
      <HomeLandingPage page={page}/>
    </Layout>
  )
}