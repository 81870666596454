import React from "react";
import Link from "next/link";

import styles from "./LoginButton.module.css"

export default function LoginButton({title, image, url}) {

  return (
    <Link href={url}>
      <div className={styles["account-type-card"]}>
        <img src={image}></img>
        <p>{title}</p>
      </div>
    </Link>
  )
}