import Head from "next/head"
import { useState, useEffect } from "react"

import Footer from "../Footer"
import { LadderAPI } from "../../lib/ladder.js"

import styles from "./HomeLandingPage.module.css"
import EmployerLoginButton from "../login/EmployerLoginButton";
import WorkerLoginButton from "../login/WorkerLoginButton";

import WPPageContent from "../WPPageContent"

export default function HomeLandingPage({category, location, workers, data, page}) {
  const [filters, setFilters] = useState();
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetchJobs();
  }, [])

  const fetchJobs = async () => {
    const res = await LadderAPI.jobsAndFilters();
    if(res.success) {
      setFilters(res.filters);
      setJobs(res.jobs)
    }
  }

  const structuredData = () => {
    const sData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Ladder",
      "url": "https://www.meetladder.com",
      "telephone": "+14047775045",
      "logo": "https://www.meetladder.com/large-logo.png",
      "description": "Ladder helps construction companies find, qualify, and hire the best trade workers. Using our rich work profiles and direct messaging tools, contractors can hire twice the workers in half the time. No more spreadsheets, phone tag, and interview no-shows. Join the top contractors using Ladder to hire their construction workforce today.",
      "areaServed": "United States of America"
    }

    return (
      <script type="application/ld+json"  dangerouslySetInnerHTML={ { __html: JSON.stringify(sData)}}></script>
    );
  }

  return(
    <div>
      <Head>
        <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
        <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript"></script>
        {structuredData()}
      </Head>

      <div className="wp-content">
        <WPPageContent
          content={page.content}
          props={{
            jobs: {jobs},
            map: {data},
            'landing-workers': {workers, category},
            'landing-searchbar': {filters},
            'landing-hero-and-logos': {category},
          }}
        />
      </div>
      <div className={styles["pre-footer"]} style={{marginBottom: -20}}>
        <div className="row justify-content-center">
          <EmployerLoginButton/>
          <WorkerLoginButton/>
        </div>
      </div>
    </div>
  )
}
